html {
  background-color: #000;
  white-space: nowrap; }

body {
  margin: 0;
  font-family: "Inconsolata", monospace;
  font-size: 19px; }

header {
  display: flex;
  align-items: center;
  background-color: #de2a2e;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 2; }
  header h1 {
    display: inline-block;
    margin: 15px 0;
    color: #fece30; }

.header__title {
  text-align: center;
  width: 90%; }

a {
  color: #fff;
  text-decoration: none; }

.list {
  background-color: #000;
  margin: 70px 0 75px 0;
  padding: 0; }
  .list.no-margin {
    margin-bottom: 0; }

.list__item {
  list-style: none;
  border-bottom: 1px solid black;
  height: 55px;
  overflow: hidden; }
  .list__item a,
  .list__item p {
    line-height: 59px;
    margin: 0;
    vertical-align: top; }
  .list__item p {
    display: inline-block;
    font-family: Consolas, monaco, monospace; }
  .list__item p:last-child {
    cursor: default;
    position: absolute;
    right: 30px; }
  .list__item:hover {
    background-color: rgba(43, 67, 100, 0.2); }

.list__item__link {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0 95px 0 30px;
  box-sizing: border-box; }
  .list__item__link:hover, .list__item__link:focus {
    text-decoration: underline; }

.list__item__duration {
  color: #fff;
  line-height: 59px;
  position: absolute;
  right: 30px; }

.player {
  background-color: white;
  border-top: 1px solid black;
  position: fixed;
  bottom: 0;
  height: 75px;
  width: 100%; }

.button {
  border: none;
  padding: 0;
  position: relative; }
  .button:not(.button__back) {
    cursor: default;
    transform: translateY(6px);
    height: 54px;
    width: 40px;
    margin: 4px; }
  .button:active, .button:focus {
    outline: none; }

.button__wrap {
  display: inline-block;
  float: left;
  height: 100%; }
  .button__wrap button {
    cursor: pointer; }

.hidden {
  display: none; }

.button__back {
  background-color: transparent;
  border: none;
  color: #1ea8e0;
  cursor: pointer;
  font-size: 2em;
  line-height: 33px; }
  .button__back:hover {
    border-bottom: 2px solid #1ea8e0;
    transform: translateY(1px); }

.button__prev {
  background: url("../img/controls.svg") -15px 0px;
  background-size: cover;
  display: none; }
  @media all and (min-width: 425px) {
    .button__prev {
      display: inline-block; } }

.button__play {
  background: url("../img/controls.svg") -127px 0px;
  background-size: cover; }

.button__play.playing {
  background: url("../img/controls.svg") -75px 0px;
  background-size: cover; }

.button__next {
  background: url("../img/controls.svg") -185px 0px;
  background-size: cover; }

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); }

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  text-align: center;
  width: 80%;
  max-width: 560px;
  white-space: normal; }
  .modal-content a {
    color: black;
    text-decoration: underline; }

#modal-1-btn {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  padding: 0; }
  #modal-1-btn:hover {
    border-bottom: 2px solid #1ea8e0;
    transform: translateY(1px); }
  #modal-1-btn:focus {
    outline: none; }

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }

.player__duration {
  display: none; }
  @media all and (min-width: 425px) {
    .player__duration {
      display: inline; } }

.player__bar {
  background-color: #000;
  border-radius: 3px;
  bottom: 20px;
  position: absolute;
  width: 100%; }

.player__progress {
  background-color: #d6d6d6;
  border-radius: 3px 0 0 3px;
  height: 10px;
  width: 0; }

.player__data {
  overflow: hidden;
  display: inline-block;
  margin-left: 8px;
  height: 100%;
  position: relative;
  width: calc(100% - 120px); }
  @media all and (min-width: 425px) {
    .player__data {
      width: calc(100% - 170px); } }

.player__data__setting {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }
  @media all and (min-width: 425px) {
    .player__data__setting {
      width: calc(100% - 115px); } }

.player__data__time {
  display: none;
  position: absolute;
  right: 17px;
  bottom: 36px; }
  @media all and (min-width: 425px) {
    .player__data__time {
      display: inline-block; } }

.player__date {
  display: none;
  position: relative;
  top: -4px; }
  @media all and (min-width: 425px) {
    .player__date {
      display: inline; } }

.player__venue {
  display: none;
  position: relative;
  top: -4px; }
  @media all and (min-width: 425px) {
    .player__venue {
      display: inline; } }

.player__title {
  display: inline-block;
  font-size: 1.5rem;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }
  @media all and (min-width: 425px) {
    .player__title {
      width: auto; } }

.player__title span {
  font-family: Consolas, monaco, monospace;
  margin-left: 18px; }
